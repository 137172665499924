import _ from "lodash";
import React from "react";
import CalendarIcon from "./calendar-icon";
import SEO from "./seo";
import Tags from "./tag-list";

export type ListingPropType = {
  data: {
    markdownRemark: {
      frontmatter: {
        date: string;
        createdDate: string;
        slug: string;
        title: string;
        categories?: string[];
      };
      html;
    };
  };
};

function BlogPost({ data }: ListingPropType) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  return (
    <article className="p-3 rounded bg-white">
      <SEO
        post={{
          title: frontmatter.title,
          path: `/posts${frontmatter.slug}`,
          titleKeyword: frontmatter.categories?.length
            ? frontmatter.categories[0]
            : null,
          createdDate: frontmatter.createdDate,
        }}
      />
      <h1 className="h2 mb-1">{frontmatter.title}</h1>
      <p className="blog-post-meta mb-2 text-muted">
        <CalendarIcon /> {frontmatter.date}
        <Tags categories={frontmatter.categories} />
      </p>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </article>
  );
}

export default BlogPost;
