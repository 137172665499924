import React from "react";
import { graphql } from "gatsby";
import BlogPost, { ListingPropType } from "../../components/blog-post";
import Layout from "../../components/layout";

export default function Template({ data }: ListingPropType) {
  return (
    <Layout>
      <div className="col col-12 col-xl-8 mx-auto">
        <BlogPost data={data} />
      </div>
    </Layout>
  );
}

// How to filter based on path?
// filter: {fileAbsolutePath: {regex: "/(posts)/.+\\.md$/"}}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        createdDate: date
        slug
        title
        categories
      }
    }
  }
`;
